import React from 'react';
import { FILTER_DROPDOWN_OFFSET } from './MainPanel';
import FilterComponent from './FilterComponent';

const SecondaryFilters = props => {
  const { secondaryFilters, initialValues, getHandleChangedValueFn } = props;
  return secondaryFilters.map(config => (
    <FilterComponent
      key={`SearchFiltersSecondary.${config.id}`}
      idPrefix="SearchFiltersSecondary"
      filterConfig={config}
      initialValues={initialValues}
      getHandleChangedValueFn={getHandleChangedValueFn}
      showAsPopup
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ));
};

export default SecondaryFilters;
