import React from 'react';
import { FILTER_DROPDOWN_OFFSET } from './MainPanel';
import { ToggleSwitch } from '../../components';
import FilterComponent from './FilterComponent';

import css from './SearchPage.module.css';

const PrimaryFilters = props => {
  const {
    primaryFilters,
    initialValues,
    getHandleChangedValueFn,
    isDesktopMapOpen,
    onToggleDesktopMap,
    toggleLabel,
  } = props;
  return (
    <>
      <div className={css.filters}>
        {primaryFilters.map(config => (
          <FilterComponent
            key={`SearchFiltersPrimary.${config.id}`}
            idPrefix="SearchFiltersPrimary"
            filterConfig={config}
            initialValues={initialValues}
            getHandleChangedValueFn={getHandleChangedValueFn}
            showAsPopup
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
          />
        ))}
      </div>
      <ToggleSwitch label={toggleLabel} isOpen={isDesktopMapOpen} onToggle={onToggleDesktopMap} />
    </>
  );
};

export default PrimaryFilters;
